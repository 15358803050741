<template>
    <div class="dynamic-state-content">
        <el-scrollbar class="dynamic-state-scrollbar">
            <div class="banner">
                <img src="../../../assets/images/index_4.png" alt="">
            </div>
            <div class="content">
                <div class="content-item">
                    <div class="carousel-title">
                        <div class="title"
                              v-for="item in manageTab"
                              :class="{current: currentTab === item.id}"
                              :key="item.id"
                              @click="changeDynamicStateTab(item.id)">
                            <span class="title-bg"></span>
                            <span class="text">{{item.name}}</span>
                        </div>
                    </div>
                    <template v-if="dynamicList.length > 0">
                        <div class="dynamic-list" v-if="!isShowDetail">
                            <div class="item" v-for="item in dynamicList">
                                <img :src="item.pic_url_path.filepath" alt="" v-if="item.notice_id" @click="viewNoticeDetail(item.notice_id)">
                                <img :src="item.pic_url_path.filepath" alt="" v-if="item.news_id" @click="viewNewsDetail(item.news_id)">
                                <div class="dynamic-info">
                                    <div class="dynamic-title" v-if="item.notice_id" :title="item.title" @click="viewNoticeDetail(item.notice_id)">{{item.title}}</div>
                                    <div class="dynamic-title" v-if="item.news_id" :title="item.title" @click="viewNewsDetail(item.news_id)">{{item.title}}</div>
                                    <div class="time-status">
                                        <span class="time">{{item.addtime}}</span>
                                        <span class="status" v-if="currentTab === '1'">{{Number(item.status) ? '已结束' : '进行中'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="no-data" v-if="dynamicList.length === 0">
                        <img src="../../../assets/images/null-data.png" alt="">
                    </div>
                    <div class="dynamic-detail" v-if="isShowDetail">
                        <div class="detail-header">
                            <el-button size="small" type="primary" plain class="btn" @click="goBackList">返回</el-button>
                            <span class="status" v-if="currentTab === '1'">{{Number(dynamicDetail.status) ? '已结束' : '进行中'}}</span>
                        </div>
                        <div class="detail-title">
                            <div class="dynamic-title" :title="dynamicDetail.title">{{dynamicDetail.title}}</div>
                            <div class="time-status">
                                <span class="time">{{dynamicDetail.addtime}}</span>
                                <span class="status">浏览次数：{{dynamicDetail.read_num}}</span>
                            </div>
                            <hr size="1"/>
                        </div>
                        <div class="detail-content" v-html="dynamicDetail.content"></div>
                    </div>
                </div>
                <el-pagination
                        v-if="!isShowDetail && dynamicList.length > 0"
                        class="pages-center"
                        :current-page="dynamicPages.currentPageNum"
                        :page-size="dynamicPages.eachPageNum"
                        layout="prev, pager, next"
                        :total="dynamicPages.total"
                        @current-change="dynamicCurrentChange">
                </el-pagination>
                <div class="content-bg">
                    <img src="../../../assets/images/index_3.png" alt="">
                </div>
            </div>
            <OfficialFooter />
        </el-scrollbar>
    </div>
</template>

<script>
    import OfficialFooter from '../../../components/OfficialFooter';
    import {notice, news, newsGet, noticeGet} from '../../../utils/apis'

    export default {
        name: "DynamicState",
        data() {
            return {
                //切换tab
                manageTab: [
                    {
                        id: '1',
                        name: '一课通知'
                    },
                    {
                        id: '2',
                        name: '一课新闻'
                    }
                ],
                currentTab: '1',
                //动态列表
                dynamicList: [],
                //分页
                dynamicPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                },
                //是否显示详情
                isShowDetail: false,
                //详情
                dynamicDetail: {
                    title: '',
                    addtime: '',
                    read_num: '',
                    content: '',
                    status: '',
                }
            }
        },
        components: {
            OfficialFooter
        },
        created() {
          this.getDynamicList('1');
          if (this.$route.query.notice_id) {
              this.viewNoticeDetail(this.$route.query.notice_id);
              this.currentTab = '1';
          }
          if (this.$route.query.news_id) {
              this.viewNewsDetail(this.$route.query.news_id);
              this.currentTab = '2';
          }
          if (this.$route.query.type === '1') {
              this.currentTab = '1';
              this.getDynamicList(this.$route.query.type);
          }
          if (this.$route.query.type === '2') {
              this.currentTab = '2';
              this.getDynamicList(this.$route.query.type);
          }
        },
        methods: {
            //切换
            changeDynamicStateTab(id) {
                this.currentTab = id;
                this.dynamicPages.currentPageNum = 1;
                this.getDynamicList(id);
                this.isShowDetail = false;
            },
            //获取动态列表
            getDynamicList(type) {
                let params = {
                    type: 2,
                    paging: 1,
                    page: this.dynamicPages.currentPageNum,
                    pageSize: this.dynamicPages.eachPageNum,
                }
                if (type === '1') {
                    notice(params).then((res) => {
                        this.dynamicList = res.data.list;
                        this.dynamicPages.total = res.data.total;
                    }).catch((err) => {
                        console.log(err);
                    });
                }
                if (type === '2') {
                    news(params).then((res) => {
                        this.dynamicList = res.data.list;
                        this.dynamicPages.total = res.data.total;
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            },
            //查看详情
            viewNoticeDetail(id) {
                this.isShowDetail = !this.isShowDetail;
                this.$router.push({
                    path: '/state/index',
                    query: {
                        notice_id: id
                    }
                })
                let params = {
                    type: '1',
                    notice_id: id || this.$route.query.notice_id
                }
                noticeGet(params).then((res) => {
                    this.dynamicDetail.addtime = res.data.addtime;
                    this.dynamicDetail.title = res.data.title;
                    this.dynamicDetail.read_num = res.data.read_num;
                    this.dynamicDetail.status = res.data.status;
                    this.dynamicDetail.content = res.data.content;
                }).catch((err) => {
                    console.log(err);
                });
            },
            viewNewsDetail(id) {
                this.isShowDetail = !this.isShowDetail;
                this.$router.push({
                    path: '/state/index',
                    query: {
                        news_id: id
                    }
                })
                let params = {
                    type: '1',
                    news_id: id || his.$route.query.news_id
                }
                newsGet(params).then((res) => {
                    this.dynamicDetail.addtime = res.data.addtime;
                    this.dynamicDetail.title = res.data.title;
                    this.dynamicDetail.read_num = res.data.read_num;
                    this.dynamicDetail.content = res.data.content;

                }).catch((err) => {
                    console.log(err);
                });
            },
            //分页
            dynamicCurrentChange(val) {
                this.dynamicPages.currentPageNum = val;
                this.getDynamicList(this.currentTab);
            },
            //返回
            goBackList() {
                this.isShowDetail = !this.isShowDetail;
            }
        },
    }
</script>

<style scoped lang="scss">
    .dynamic-state-content {
        width: 100%;
        height: 100%;
        .dynamic-state-scrollbar {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .banner {
            position: relative;
            z-index: 10;
            width: 100%;
            max-height: 616px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            min-height: 616px;
            background-color: #fff;
            .content-bg {
                height: 100%;
                margin-top: -900px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .content-item {
                position: relative;
                z-index: 10;
                width: 1200px;
                min-height: 500px;
                margin-top: -280px;
                .carousel-title {
                    display: flex;
                    width: 260px;
                    height: 50px;
                    margin-left: 40px;
                    .title {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        font-size: 18px;
                        margin-right: 10px;
                        cursor: pointer;
                        &:hover {
                            .text {
                                background: #F44E3D;
                                opacity: 1;
                            }
                        }
                        .title-bg {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: #000000;
                            opacity: 0.4;
                            border-radius: 6px 6px 0 0;
                        }
                        .text {
                            position: absolute;
                            display: block;
                            width: 100%;
                            height: 100%;
                            line-height: 50px;
                            text-align: center;
                            border-radius: 6px 6px 0 0;
                        }
                    }
                    .current {
                        .title-bg {
                            background-color: transparent;
                            opacity: 1;
                        }
                        .text {
                            background: #F44E3D;
                            opacity: 1;
                        }
                    }
                }
                .no-data {
                    margin-top: 80px;
                    text-align: center;
                }
                .dynamic-list {
                    min-height: 850px;
                    .item {
                        display: flex;
                        padding: 40px 0 30px 40px;
                        border-bottom: 1px solid #DDDDDD;
                        &:first-child {
                            background-color: #fff;
                            border-radius: 6px 6px 0 0;
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                        img {
                            width: 280px;
                            height: 200px;
                            cursor: pointer;
                        }
                        .dynamic-info {
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 1%;
                            margin-left: 22px;
                            .dynamic-title {
                                color: #333;
                                font-size: 18px;
                                overflow: hidden;
                                text-overflow:ellipsis;
                                white-space: nowrap;
                                cursor: pointer;
                            }
                            .time-status {
                                display: flex;
                                justify-content: space-between;
                                .time {
                                    color: #999999;
                                    font-size: 16px;
                                }
                                .status {
                                    display: block;
                                    width: 80px;
                                    height: 26px;
                                    line-height: 26px;
                                    text-align: center;
                                    background: #F36F21;
                                    border-radius: 6px;
                                    color: #fff;
                                    font-size: 12px;
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
                .dynamic-detail {
                    display: flex;
                    flex-direction: column;
                    min-height: 850px;
                    background-color: #ffffff91;
                    .detail-header {
                        display: flex;
                        justify-content: space-between;
                        background-color: #ffffffe8;
                        padding: 40px 40px 0 40px;
                        border-radius: 6px 6px 0 0;
                        .status {
                            width: 90px;
                            height: 26px;
                            background: #F36F21;
                            border-radius: 13px;
                            color: #fff;
                            display: block;
                            line-height: 26px;
                            text-align: center;
                        }
                    }
                    .detail-title {
                        display: flex;
                        flex-direction: column;
                        padding: 20px 0 70px 40px;
                        line-height: 2;
                        background-color: #ffffffe8;
                        hr {
                            width: calc(100% - 40px);
                            height: 1px;
                            color: #DDDDDD;
                            border: 1px solid #DDDDDD;
                            box-sizing: border-box;
                            text-align: center;
                            margin: 20px 0 0 0;
                        }
                        .dynamic-title {
                            color: #333333;
                            font-size: 18px;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                        .time-status {
                            color: #999999;
                            font-size: 16px;
                            .time {
                                margin-right: 40px;
                            }
                        }
                    }
                    .detail-content {
                        padding: 20px 40px 40px 40px;
                    }
                    .btn {
                        padding: 10px 30px;
                    }
                }
            }
            .pages-center {
                position: relative;
                z-index: 999;
                background: transparent;
                ::v-deep button:disabled {
                    background: transparent;
                    border-radius: 50%;
                    border: 1px solid #E6E6E6;
                }
                ::v-deep .btn-next {
                    min-width: 28px;
                    padding: 0;
                    background: transparent;
                    border-radius: 50%;
                    border: 1px solid #E6E6E6;
                }
                ::v-deep .btn-prev {
                    min-width: 28px;
                    padding: 0;
                    background: transparent;
                    border-radius: 50%;
                    border: 1px solid #E6E6E6;
                }
                ::v-deep .el-pager {
                    .number {
                        height: 28px;
                        min-width: 28px;
                        line-height: 28px;;
                        background: transparent;
                        border-radius: 50%;
                        border: 1px solid #E6E6E6;
                    }
                    .active {
                        background: #F36F21;
                    }
                }
            }
        }
    }
</style>
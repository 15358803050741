<template>
  <div class="about-footer-bj">
    <div class="about-footer">
      <div class="about-footer-top">
        <div class="footer-top-left">
          <div class="service-hot">
            <i class="iconfont">&#xedfd;</i>
            <div class="hot">
              <span>全国客服热线</span>
              <span>13338466186</span>
            </div>
          </div>
          <div class="relation-we">
            <span @click="relationWe">联系我们</span>
          </div>
          <div class="company-address">
            公司地址：厦门市思明区软件园二期观日路34号之三401室<span>邮箱：info@yikejiaoyu.com</span>
          </div>
        </div>
        <div class="footer-top-right">
          <img src="./../assets/images/QR-code.png" alt="">
          <span>一课教育公众号</span>
        </div>
      </div>
      <div class="about-footer-bottom">
        <span>Copyright © 2017, ECLASS TECH CO.,LTD. All Rights Reserved. 厦门一课信息技术服务有限公司  |  法律声明  |  隐私条款  |  开发者中心<a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 30px">闽ICP备15019684号</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfficialFooter",
  methods:{
    relationWe(){
      if(this.$route.path === '/about'){
        this.$emit('amendTabIndex')
      } else {
        this.$router.push({
          path:'/about',
          query:{
            contactUs:4 //跳转到关于一课-联系我们的标识
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.about-footer-bj{
  width: 100%;
  background: #03032F;
  padding: 40px 0;
  .about-footer{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .about-footer-top{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #999999;
      padding-bottom: 28px;
      .footer-top-left{
        .service-hot{
          display: flex;
          align-items: center;
          i{
            margin-right: 20px;
            color: #F36F21;
            font-size: 46px;
            line-height: 1;
          }
          .hot{
            display: flex;
            flex-direction: column;
            span{
              line-height: 1;
              color: #fff;
              &:nth-child(1){
                font-size: 14px;
              }
              &:nth-child(2){
                margin-top: 10px;
                font-size: 24px;
              }
            }
          }
        }
        .relation-we,.company-address{
          color: #999999;
          font-size: 14px;
          line-height: 1;
        }
        .relation-we{
          margin-top: 44px;
          span{
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        .company-address{
          margin-top: 12px;
          span{
            margin-left: 40px;
          }
        }
      }
      .footer-top-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 110px;
        img{
          max-width: 100%;
        }
        span{
          color: #999999;
          font-size: 12px;
          line-height: 1;
          margin-top: 10px;
        }
      }
    }
    .about-footer-bottom{
      text-align: center;
      padding-top: 28px;
      span,a{
        color: #999999;
        font-size: 14px;
        line-height: 1;
      }
      a{
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
</style>